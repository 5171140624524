<template>
  <div class="page">
    <van-loading v-if="state.loading" class="mt-5" color="#7232dd" size="36" vertical>
      {{ state.msg }}
    </van-loading>
    <van-empty v-else-if="state.error" image="error" :description="state.msg" />
    <div v-else class="content">
      <template v-if="order.payment_status == 0">
        <div class="d-none">
          <van-config-provider :theme-vars="themeVars">
            <van-tabs v-model:active="form.sex" @change="loadTemplates">
              <van-tab title="女生"></van-tab>
              <van-tab title="男生"></van-tab>
            </van-tabs>
          </van-config-provider>
        </div>
        <van-cell-group title="Midjourney版本">
          <div class="d-flex justify-content-between my-2 clickarea">
            <template v-for="(item, index) of [5.2, 'niji5']" :key="index">
              <div class="mini" :class="form.version == item ? 'active' : ''" @click="form.version = item">{{ item }}
              </div>
            </template>
          </div>
        </van-cell-group>
        <van-cell-group title="出图比例">
          <div class="d-flex justify-content-between my-2 clickarea">
            <template v-for="(item, index) of ['1:1', '1:2', '3:4', '9:16']" :key="index">
              <div class="mini" :class="form.aspect == item ? 'active' : ''" @click="form.aspect = item">{{ item }}</div>
            </template>
          </div>
        </van-cell-group>
        <van-cell-group title="预设模板 (可以不选择模板, 自己输入提示词)">
          <div v-if="!templates.length" class="emptytips my-2">暂无可用模板</div>
          <div v-else class="d-flex flex-wrap justify-content-between my-2 clickarea">
            <template v-for="(item, index) of templates" :key="index">
              <div :class="form.style_id == item.id ? 'active' : ''" :style="`background-image: url(${item.icon})`"
                @click="handleTemplateChange(item)"></div>
            </template>
          </div>
        </van-cell-group>
        <van-cell-group title="提示词 (推荐使用英文)">
          <van-field v-model="form.prompt" rows="4" label="" type="textarea" placeholder="请输入英文提示词, 例如: a beautiful asian girl" />
        </van-cell-group>
        <van-action-bar>
          <van-action-bar-button type="danger" color="#7232dd" :disabled="!form.style_id ? true : false" text="生成图像"
            loading-text="请稍候..." @click="handleBalancePay" />
        </van-action-bar>
      </template>
      <success-form v-else-if="order.status == 'finished'" :order="order" />
      <processing-form v-else-if="order.status == 'waiting' || order.status == 'processing'" :order="order" />
      <refund-form v-else-if="order.status == 'error'" :order="order" />
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  ActionSheet,
  ActionBar,
  ActionBarButton, Empty, Image as VanImage, Toast, Tab, Tabs
} from "vant";
import { onMounted, reactive, toRaw } from "@vue/runtime-core";
import { setDocumentTitle } from "@/utils/domUtil";
import { getOrderDetail, preparePay, pay, getTemplates } from "@/api/nijibot.service";
import { signaturePack } from "@/api/wechat.service";
import SuccessForm from "./components/SuccessForm";
import ProcessingForm from "./components/ProcessingForm";
import RefundForm from "./components/RefundForm";

export default {
  props: {
    trade_no: {
      type: String,
      require: true,
    },
  },
  components: {
    SuccessForm,
    ProcessingForm,
    RefundForm,
    [ActionSheet.name]: ActionSheet,
    [ActionBar.name]: ActionBar,
    [ActionBarButton.name]: ActionBarButton,
    [Empty.name]: Empty,
    [VanImage.name]: VanImage,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  setup(props) {
    const state = reactive({
      loading: true,
      error: false,
      msg: "请稍候",
      signaturePageStep: 'wait',  // wait待执行, success成功(仅此状态可以发起微信支付), failed失败
    });
    const form = reactive({
      version: 5.2,
      aspect: "1:2",
      image_num: 4, // 默认选择出4图, 目前系统不按照图像数量来收费
      sex: 0, // 0女, 1男
      style_id: -1,
      style: "raw",
      stylize: 250,
      prompt: "",
    });

    const showErrorMessage = (msg) => {
      state.error = true;
      state.msg = msg;
    }

    const signaturePage = async () => {
      try {
        const url = /(Android)/i.test(navigator.userAgent)
          ? location.href.split("#")[0]
          : window.entryUrl;
        const { result } = await signaturePack(url);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "closeWindow", "chooseWXPay"],
        });
        wx.ready(() => {
          state.signaturePageStep = "success";
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          state.signaturePageStep = "failed";
          showErrorMessage(res.errMsg);
        });
      } catch (error) {
        state.signaturePageStep = "failed";
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "前端脚本异常/页面签名失败");
        if (!msg) console.log(error);
      }
    };

    const order = reactive({});
    const templates = reactive([]);
    onMounted(() => {
      setDocumentTitle('尼基波特');
      signaturePage();
      loadOrder();
      loadTemplates();
      state.loading = false;
    });

    const loadOrder = async () => {
      try {
        const { trade_no } = props;
        let res = await getOrderDetail(trade_no);
        Object.assign(order, res.result);
      } catch (error) {
        state.loading = false;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    };

    const loadTemplates = async () => {
      try {
        const { result } = await getTemplates({
          sex: form.sex == 0 ? 'female' : 'male',
        });
        form.style_id = -1;
        templates.length = 0;
        if (result.items.length) {
          // form.style_id = result.items[0].id;
          Object.assign(templates, result.items);
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    };

    const handleBalancePay = async () => {
      if (!form.prompt) {
        return Toast("请输入提示");
      }
      try {
        Toast.loading({
          message: '请稍候...',
          forbidClick: true,
          duration: 0,
        });
        const { trade_no } = props;
        await pay({ ...toRaw(form), trade_no });
        Toast.success({
          message: "提交成功",
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        setTimeout(() => {
          loadOrder();
          Toast.clear();
        }, 2000);

      } catch (error) {
        Toast.clear();
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    };


    // 微信支付: 已弃用
    const handleUpdateOrder = async () => {
      if (!form.style_id) {
        return Toast("请选择模板");
      }
      try {
        Toast.loading({
          message: '请稍候...',
          forbidClick: true,
          duration: 0,
        });
        const { trade_no } = props;
        const { result } = await preparePay({ ...toRaw(form), trade_no });
        wx.chooseWXPay({
          timestamp: result.timeStamp,
          nonceStr: result.nonceStr,
          package: result.package,
          signType: result.signType,
          paySign: result.paySign,
          success: () => {
            Toast.success({
              message: "支付成功",
              forbidClick: true,
              overlay: true,
              duration: 0
            });
            setTimeout(() => {
              wx.closeWindow();
            }, 4000);
          },
          error: (res) => {
            showErrorMessage(res.errMsg ? res.errMsg : "支付失败");
            setTimeout(() => {
              wx.closeWindow();
            }, 4000);
          },
          cancel: () => {
            showErrorMessage("您已取消支付");
            setTimeout(() => {
              wx.closeWindow();
            }, 4000);
          },
        });
      } catch (error) {
        Toast.clear();
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    }

    const themeVars = {
      tabsBottomBarColor: "#7232dd"
    };

    const handleTemplateChange = (item) => {
      const params = JSON.parse(item.params);
      form.style_id = item.id;
      form.prompt = item.prompt_female;
      form.version = params.version;
      form.aspect = params.aspect;
      form.style = params.style;
      form.stylize = params.stylize;
    }

    return { state, order, form, templates, handleUpdateOrder, loadTemplates, themeVars, handleTemplateChange, handleBalancePay };
  },
};
</script>

<style scoped>
.content {
  padding-bottom: 100px;
}

.clickarea {
  padding: 16px;
}

.clickarea div {
  width: 60px;
  height: 60px;
  line-height: 56px;
  border: 2px solid #e5e3ea;
  text-align: center;
  border-radius: 10px;
  margin: 5px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.clickarea div.mini {
  width: 60px;
  height: 40px;
  line-height: 36px;
}

.clickarea div.active {
  background-color: #be99ff4d;
  border: 3px solid #7232dd;
}

.emptytips {
  height: 122px;
  line-height: 122px;
  text-align: center;
  color: #7232dd;
}
</style>