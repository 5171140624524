<template>
  <div class="nijibot-warp">
    <van-icon v-if="order.payment_status == 4" class="gray mb-4" size="160" name="clock-o" />
    <van-icon v-else-if="order.payment_status == 6" class="green mb-4" size="160" name="passed" />
    <van-icon v-else class="gray mb-4" size="160" name="close" />
    <div class="desc">
      <p>非常抱歉, 本次生成图片失败！</p>
      <!-- <p class="small text-muted">{{ order.trade_no }}</p> -->
    </div>
    <div class="text-center">
      <van-button v-if="order.payment_status == 4 || disableRefund" type="danger" plain disabled>退款处理中</van-button>
      <van-button v-else-if="order.payment_status == 6" type="success" plain disabled>Token已退回余额</van-button>
      <van-button v-else-if="order.payment_status == 7" type="danger" plain disabled>Token退回失败</van-button>
      <van-button v-else-if="order.payment_status == 1" type="danger" plain @click="handleRefund">点此退款</van-button>
    </div>
  </div>
</template>

<script>
import {
  Toast
} from "vant";
import { refundOrder } from "@/api/nijibot.service";
import { ref } from "vue";

export default {
  props: {
    order: {
      required: false,
      type: Object,
      default: () => {
        return { trade_no: "" };
      },
    },
  },
  setup(props) {
    const disableRefund = ref(false);
    const handleRefund = async () => {
      try {
        Toast.loading("提交中");
        const { trade_no } = props.order;
        await refundOrder(trade_no);
        disableRefund.value = true;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast.fail(msg);
      }
    }
    return { disableRefund, handleRefund }
  },
}
</script>
 