<template>
  <div class="nijibot-warp">
    <van-icon class="green mb-4" size="160" name="passed" />
    <div class="desc">
      <p>恭喜, 您的图片已经生成成功！</p>
      <p class="small">请在公众号内获取结果</p>
      <!-- <p class="small text-muted">{{ order.trade_no }}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      required: false,
      type: Object,
      default: () => {
        return { trade_no: "" };
      },
    },
  },
}
</script>
