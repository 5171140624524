<template>
  <div class="nijibot-warp">
    <van-icon class="gray mb-4" size="160" name="clock-o" />
    <div class="desc">
      <p>您的订单正在处理中</p>
      <p class="small">请稍后在公众号内获取结果</p>
      <!-- <p class="small text-muted">{{ order.trade_no }}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      required: false,
      type: Object,
      default: () => {
        return { trade_no: "" };
      },
    },
  },
}
</script>
